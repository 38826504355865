import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
// import Vconsole from 'vconsole'
import i18n from './i18n'
import weixin from 'weixin-js-sdk'

Vue.prototype.wx = window.wx || weixin

Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant)
Vue.prototype.$axios = axios;

Vue.prototype.apiuri = "https://api.aicoiot.com/order.json";

// const vConsole = new Vconsole()
// Vue.use(vConsole)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
