<template>
  <div id="app">
    <router-view />
    <div class="bottom_logo">
      <div>Powered By</div>
      <van-image width="100" :src="logo" />
    </div>
    <van-overlay :show="!onLine">
      <van-empty image="network" :description="$t('global.networkerror')" />
    </van-overlay>
  </div>
</template>
<script>
/**
 * Starter page component
 */
 import i18n from "./i18n";

export default {
  data() {
    return {
      logo: "",
      onLine: navigator.onLine,
    };
  },
  mounted() {
    this.logo = require("@/assets/logo.png");
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    // document.title = "收银台 - 激萌云物联啤酒机"
    
    const clientLanguage = navigator.language || navigator.userLanguage;
    const formattedLanguage = clientLanguage.toLowerCase();
    i18n.locale = formattedLanguage
    // i18n.locale = "en-us"
    this.loadingdata()
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    loadingdata(){
      document.title = this.$t("title")
    }
  },
};
</script>
<style>
.card-body {
  padding-top: 15%;
  min-height: 350px;
  height: 100%;
}
.bottom_logo {
  width: 100%;
  position: fixed;
  z-index: -1;
  bottom: 15px;
  text-align: center;
  filter: Gray;
  -webkit-filter: grayscale(100%);
}
.bottom_logo div {
  margin-bottom: 5px;
  color: gray;
}
.van-overlay .van-empty{
  margin-top: 50%;
}
</style>