import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
  },
  {
    path: '/list/:sn',
    name: 'itemlist',
    component: () => import('../views/ItemList')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../views/Detail')
  },
  {
    path: '/cashier/:id',
    name: 'cashier',
    component: () => import('../views/Cashier')
  },
  {
    path: '/offline/:id',
    name: 'offline',
    component: () => import('../views/Offline')
  },
  {
    path: '/success/:id',
    name: 'success',
    component: () => import('../views/Success')
  },
  {
    path: '/faild/:id',
    name: 'faild',
    component: () => import('../views/Failed')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
